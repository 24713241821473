import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';

interface ReplaceDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  currentItem: string;
  newItem: string;
  mediaType: 'movie' | 'tv';
}

export const ReplaceDialog: React.FC<ReplaceDialogProps> = ({
  open,
  onClose,
  onConfirm,
  currentItem,
  newItem,
  mediaType
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Replace Current {mediaType === 'movie' ? 'Movie' : 'TV Show'}?</DialogTitle>
      <DialogContent>
        <Typography>
          "{currentItem}" is currently playing. Would you like to mark it as finished and start watching "{newItem}"?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="inherit">Cancel</Button>
        <Button onClick={onConfirm} variant="contained" color="primary">
          Replace
        </Button>
      </DialogActions>
    </Dialog>
  );
}; 