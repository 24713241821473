import React, { useState } from 'react';
import { Box, Card, CardContent, CardMedia, Typography, IconButton, Button, Grid, Chip } from '@mui/material';
import { PlayCircle as PlayCircleIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { updateCurrentlyWatching, removeItemFromWatchlist, markAsFinished } from '../../services/watchlist';
import { SerializedWatchlistItem } from '../../store/slices/watchlistSlice';
import { ReplaceDialog } from './ReplaceDialog';

export const MediaList = () => {
  const { activeWatchlist, watchlistItems } = useSelector((state: RootState) => state.watchlist);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const [replaceDialogState, setReplaceDialogState] = useState<{
    open: boolean;
    currentItem: string;
    newItem: SerializedWatchlistItem;
    mediaType: 'movie' | 'tv';
  }>({
    open: false,
    currentItem: '',
    newItem: {} as SerializedWatchlistItem,
    mediaType: 'movie'
  });

  if (!activeWatchlist || !currentUser) return null;

  const handleMoveToNowPlaying = async (item: SerializedWatchlistItem) => {
    if (!activeWatchlist?.id) {
      alert('No active watchlist found');
      return;
    }

    const isMovie = item.type === 'movie';
    const currentlyPlaying = isMovie ? activeWatchlist.currentMovie : activeWatchlist.currentTvShow;
    const lastAddedBy = isMovie ? activeWatchlist.lastMovieAddedBy : activeWatchlist.lastTvShowAddedBy;

    // Count items of the same type
    const sameTypeItems = watchlistItems.filter(i => i.type === item.type);
    const isUsersTurn = sameTypeItems.length <= 1 || !lastAddedBy || lastAddedBy !== currentUser?.id;

    if (!isUsersTurn) {
      alert(`It's not your turn to pick a ${isMovie ? 'movie' : 'TV show'}. Let your partner choose next!`);
      return;
    }

    if (currentlyPlaying && currentlyPlaying.id) {
      // Show replace dialog instead of alert
      setReplaceDialogState({
        open: true,
        currentItem: currentlyPlaying.title,
        newItem: item,
        mediaType: item.type
      });
      return;
    }

    try {
      await updateCurrentlyWatching(activeWatchlist.id, item, item.type);
      await removeItemFromWatchlist(item.id);
    } catch (error) {
      console.error('Error moving to now playing:', error);
      alert('Failed to move item to Now Playing');
    }
  };

  const handleReplace = async () => {
    if (!activeWatchlist?.id) return;
    
    try {
      // First mark the current item as finished
      await markAsFinished(activeWatchlist.id, replaceDialogState.mediaType);
      
      // Then move the new item to now playing
      await updateCurrentlyWatching(activeWatchlist.id, replaceDialogState.newItem, replaceDialogState.mediaType);
      await removeItemFromWatchlist(replaceDialogState.newItem.id);
      
      setReplaceDialogState(prev => ({ ...prev, open: false }));
    } catch (error) {
      console.error('Error replacing item:', error);
      alert('Failed to replace item');
    }
  };

  const handleRemove = async (itemId: string) => {
    try {
      await removeItemFromWatchlist(itemId);
    } catch (error) {
      console.error('Error removing item:', error);
    }
  };

  if (watchlistItems.length === 0) {
    return (
      <Box sx={{ textAlign: 'center', py: 4 }}>
        <Typography variant="body1" color="text.secondary">
          Your watchlist is empty. Add some movies or TV shows!
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2}>
        {watchlistItems.map((item) => {
          const isMovie = item.type === 'movie';
          const lastAddedBy = isMovie ? activeWatchlist.lastMovieAddedBy : activeWatchlist.lastTvShowAddedBy;
          
          // Count items of the same type for turn checking
          const sameTypeItems = watchlistItems.filter(i => i.type === item.type);
          const isDisabled = sameTypeItems.length > 1 && lastAddedBy === currentUser.id;

          return (
            <Grid item xs={12} key={item.id}>
              <Card sx={{ 
                display: 'flex', 
                height: '100%', 
                flexDirection: { xs: 'column', sm: 'row' },
                maxWidth: { sm: '800px', md: '900px' },
                mx: 'auto'
              }}>
                <CardMedia
                  component="img"
                  sx={{ 
                    width: { xs: '100%', sm: 200 },
                    height: { xs: 300, sm: 'auto' },
                    objectFit: 'cover'
                  }}
                  image={`https://image.tmdb.org/t/p/w400${item.posterPath}`}
                  alt={item.title}
                />
                <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                  <CardContent sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                      <Typography variant="h6" sx={{ fontSize: { xs: '1.25rem', sm: '1.5rem' } }}>{item.title}</Typography>
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        <Chip label={isMovie ? 'Movie' : 'TV Show'} color="primary" size="small" />
                        {item.addedBy === currentUser.id && (
                          <IconButton 
                            size="small" 
                            onClick={() => handleRemove(item.id)}
                            color="error"
                            sx={{ p: 0.5 }}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        )}
                      </Box>
                    </Box>
                    <Typography 
                      variant="body1" 
                      color="text.secondary"
                      sx={{ 
                        mb: 2,
                        display: '-webkit-box',
                        WebkitLineClamp: { xs: 3, sm: 4 },
                        WebkitBoxOrient: 'vertical',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        lineHeight: 1.6
                      }}
                    >
                      {item.overview}
                    </Typography>
                    <Box sx={{ mt: 'auto' }}>
                      <Typography variant="caption" color="text.secondary" display="block">
                        Added by: {item.addedBy === currentUser.id ? 'You' : 'Partner'}
                      </Typography>
                      <Typography variant="caption" color="text.secondary" display="block" sx={{ mb: 2 }}>
                        {new Date(item.addedAt).toLocaleDateString()}
                      </Typography>
                      <Button
                        variant="outlined"
                        onClick={() => handleMoveToNowPlaying(item)}
                        startIcon={<PlayCircleIcon />}
                        disabled={isDisabled}
                        fullWidth
                        sx={{
                          textTransform: 'none',
                          minHeight: { xs: '40px', sm: '44px' },
                          fontSize: '1rem'
                        }}
                      >
                        {isDisabled ? "It's not your turn" : "Move to Now Playing"}
                      </Button>
                    </Box>
                  </CardContent>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      <ReplaceDialog
        open={replaceDialogState.open}
        onClose={() => setReplaceDialogState(prev => ({ ...prev, open: false }))}
        onConfirm={handleReplace}
        currentItem={replaceDialogState.currentItem}
        newItem={replaceDialogState.newItem.title}
        mediaType={replaceDialogState.mediaType}
      />
    </>
  );
}; 