import React from 'react';
import { Box } from '@mui/material';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';

const popAnimationLeft = keyframes`
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(-30px, -60px) scale(0.5);
    opacity: 0;
  }
`;

const popAnimationRight = keyframes`
  0% {
    transform: translate(0, 0) scale(1);
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(30px, -60px) scale(0.5);
    opacity: 0;
  }
`;

interface KernelProps {
  animationDelay: string;
  goRight?: boolean;
}

const PopcornKernel = styled('div')<KernelProps>`
  position: absolute;
  width: 10px;
  height: 10px;
  background: #f4d03f;
  border-radius: 50%;
  transform-origin: center bottom;
  animation: ${props => props.goRight ? popAnimationRight : popAnimationLeft} 1s ease-out infinite;
  animation-delay: ${props => props.animationDelay};

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    transform: scale(0.8);
  }
`;

const Bowl = styled('div')`
  position: relative;
  width: 100px;
  height: 50px;
  background: #e74c3c;
  border-radius: 0 0 50px 50px;
  overflow: visible;
  
  &::before {
    content: '';
    position: absolute;
    width: 120px;
    height: 60px;
    background: transparent;
    border: 10px solid #e74c3c;
    border-radius: 50%;
    top: -30px;
    left: -20px;
  }
`;

export const PopcornLoader = () => {
  return (
    <Box sx={{ position: 'relative', width: 100, height: 100, margin: 'auto' }}>
      <Bowl>
        {[...Array(8)].map((_, i) => (
          <PopcornKernel
            key={i}
            animationDelay={`${i * 0.2}s`}
            goRight={Math.random() > 0.5}
            style={{
              left: `${20 + (i * 60 / 7)}%`,  // Spread kernels from 20% to 80% across the bowl
              bottom: '50px',
            }}
          />
        ))}
      </Bowl>
    </Box>
  );
};
