import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Alert, Container, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { createWatchlist } from '../../services/watchlist';
import { PopcornLoader } from '../common/PopcornLoader';
import { useMinimumLoadingTime } from '../../hooks/useMinimumLoadingTime';

export const WatchlistSetup: React.FC = () => {
  const [watchlistName, setWatchlistName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const shouldShowLoader = useMinimumLoadingTime(isLoading);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const handleCreateWatchlist = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!currentUser) {
      setError('Please sign in to create a watchlist');
      return;
    }

    if (!watchlistName.trim()) {
      setError('Please enter a watchlist name');
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      console.log('Creating watchlist...');
      const watchlistId = await createWatchlist(watchlistName, currentUser.id);
      console.log('Watchlist created:', watchlistId);
      setSuccess('Watchlist created successfully!');
      setWatchlistName('');
    } catch (error: any) {
      console.error('Error creating watchlist:', error);
      if (error.message.includes('permission-denied')) {
        setError('Permission denied. Please check your account settings.');
      } else {
        setError(error.message || 'Failed to create watchlist');
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (shouldShowLoader) {
    return <PopcornLoader />;
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center',
        minHeight: '100vh',
        position: 'relative'
      }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Create a Watchlist
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'center' }}>
          Start by creating a watchlist. You can invite your partner to join after it's created.
        </Typography>
        <Box component="form" onSubmit={handleCreateWatchlist} sx={{ width: '100%' }}>
          <TextField
            fullWidth
            label="Watchlist Name"
            variant="outlined"
            value={watchlistName}
            onChange={(e) => setWatchlistName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Create Watchlist'}
          </Button>
        </Box>
        <Typography 
          variant="caption" 
          sx={{ 
            position: 'fixed', 
            bottom: '4px', 
            right: '4px', 
            opacity: 0.5 
          }}
        >
          v1.0.2
        </Typography>
      </Box>
    </Container>
  );
}; 