import React, { useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Container,
  CircularProgress,
  Alert,
} from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { signInWithGoogle, createOrUpdateUser, DBUser, auth } from '../../services/firebase';
import { acceptInvitation } from '../../services/watchlist';
import { PopcornLoader } from '../common/PopcornLoader';
import GoogleIcon from '@mui/icons-material/Google';
import { doc, getDoc } from 'firebase/firestore';
import { pendingInvitesRef, usersRef } from '../../services/firebase';

export const Signup: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const inviteId = searchParams.get('invite');

  const handleGoogleSignup = async () => {
    if (isLoading) return;
    
    setIsLoading(true);
    setError(null);
    try {
      console.log('Starting Google sign-in process...');
      const user = await signInWithGoogle();
      console.log('Sign-in successful, creating/updating user document');
      await createOrUpdateUser(user);

      if (inviteId) {
        console.log('Found invite ID, checking invite...', {
          inviteId,
          userEmail: user.email
        });
        const inviteDoc = await getDoc(doc(pendingInvitesRef, inviteId));
        
        if (!inviteDoc.exists()) {
          console.error('Invite not found:', { inviteId });
          throw new Error('Invite not found or already accepted');
        }

        const inviteData = inviteDoc.data();
        console.log('Found invite data:', {
          inviteEmail: inviteData.email,
          userEmail: user.email,
          watchlistId: inviteData.watchlistId
        });
        
        if (inviteData.email.toLowerCase() !== user.email?.toLowerCase()) {
          console.error('Email mismatch:', {
            inviteEmail: inviteData.email.toLowerCase(),
            userEmail: user.email?.toLowerCase()
          });
          throw new Error('This invite is for a different email address');
        }

        console.log('Accepting watchlist invitation...', {
          userId: user.uid,
          watchlistId: inviteData.watchlistId,
          inviteId
        });
        const acceptedWatchlistId = await acceptInvitation(user.uid, inviteData.watchlistId, inviteId);
        console.log('Successfully accepted invitation to watchlist:', {
          acceptedWatchlistId,
          userEmail: user.email
        });
        
        // Store in localStorage to handle page refreshes
        localStorage.setItem('acceptedWatchlistId', acceptedWatchlistId);
        
        // Navigate to home with state
        navigate('/', { 
          replace: true,
          state: { 
            fromInviteAcceptance: true,
            acceptedWatchlistId 
          }
        });
        setSuccess('Successfully joined the watchlist!');
      } else {
        // Only navigate to home if there was no invite
        console.log('No invite, navigating to home');
        navigate('/', { replace: true });
      }
    } catch (error: any) {
      console.error('Sign-up error:', {
        error,
        code: error.code,
        message: error.message,
        name: error.name,
        stack: error.stack,
        context: {
          inviteId,
          userEmail: auth.currentUser?.email
        }
      });
      
      // Show user-friendly error messages
      if (error.code === 'auth/popup-closed-by-user') {
        setError('Sign up was cancelled. Please try again.');
      } else if (error.code === 'auth/popup-blocked') {
        setError('Pop-up was blocked by your browser. Please allow pop-ups for this site.');
      } else if (error.code === 'auth/cancelled-popup-request') {
        setError('Another sign up attempt is in progress. Please wait.');
      } else if (error.code === 'auth/network-request-failed') {
        setError('Network error. Please check your internet connection and try again.');
      } else if (error.code === 'permission-denied') {
        setError('Permission denied. Please try again or contact support.');
      } else if (error.message.includes('Invite not found')) {
        setError('This invite link has already been used or is no longer valid. Please ask for a new invite.');
      } else if (error.message.includes('different email address')) {
        setError('This invite was sent to a different email address. Please sign up with the email address that received the invite.');
      } else if (error.message.includes('User data not found')) {
        setError('There was a problem setting up your account. Please try signing up again.');
      } else {
        // Create a unique error code to reference
        const errorCode = Math.random().toString(36).substring(7);
        setError(`Something went wrong (Error: ${errorCode}). Please let your friend know this error code so they can help fix it.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <PopcornLoader />;
  }

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
        textAlign="center"
      >
        <Box mb={4}>
          <img
            src="/urnext-logo.png"
            alt="urNext Logo"
            style={{ width: '200px', height: 'auto' }}
          />
        </Box>

        <Typography variant="h5" gutterBottom>
          {inviteId ? "Accept Watchlist Invitation" : "Sign Up"}
        </Typography>

        {error && (
          <Box mb={3} width="100%">
            <Alert severity="error">{error}</Alert>
          </Box>
        )}

        {success && (
          <Box mb={3} width="100%">
            <Alert severity="success">{success}</Alert>
          </Box>
        )}

        <Button
          variant="contained"
          color="primary"
          onClick={handleGoogleSignup}
          disabled={isLoading}
          startIcon={!isLoading && <GoogleIcon />}
          size="large"
          sx={{ minWidth: 200 }}
        >
          {isLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            'Continue with Google'
          )}
        </Button>
      </Box>
    </Container>
  );
}; 