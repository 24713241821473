import { Box, Typography, Card, CardContent, CardMedia, Chip, IconButton, Rating, Tooltip, Button } from '@mui/material';
import { Delete as DeleteIcon, ArrowBack as ArrowBackIcon, Check as CheckIcon } from '@mui/icons-material';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store/store';
import { removeItemFromWatchlist, updateCurrentlyWatching, markAsFinished, moveItemBackToWatchlist } from '../../services/watchlist';
import { keyframes } from '@mui/material';
import { SerializedWatchlistItem } from '../../store/slices/watchlistSlice';
import { MediaItem } from '../../store/slices/mediaSlice';

const glowAnimation = keyframes`
  0% { box-shadow: 0 0 10px rgba(255, 215, 0, 0.5); }
  50% { box-shadow: 0 0 20px rgba(255, 215, 0, 0.8); }
  100% { box-shadow: 0 0 10px rgba(255, 215, 0, 0.5); }
`;

const marqueeGlow = keyframes`
  0% { text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000; }
  50% { text-shadow: 0 0 20px #ff0000, 0 0 30px #ff0000, 0 0 40px #ff0000; }
  100% { text-shadow: 0 0 10px #ff0000, 0 0 20px #ff0000, 0 0 30px #ff0000; }
`;

interface MediaCardProps {
  item: SerializedWatchlistItem;
  onMarkFinished: () => void;
  onMoveBack: () => void;
  onDelete: () => void;
  type: 'movie' | 'tv';
}

const MediaCard = ({ item, onMarkFinished, onMoveBack, onDelete, type }: MediaCardProps) => {
  if (!item) return null;

  return (
    <Card sx={{ 
      display: 'flex', 
      mb: 2,
      animation: `${glowAnimation} 2s infinite ease-in-out`,
      bgcolor: 'background.paper',
      borderRadius: 2,
      flexDirection: { xs: 'column', sm: 'row' },
      height: { xs: 'auto', sm: '300px' },
      maxWidth: { sm: '800px' },
      width: '100%'
    }}>
      <CardMedia
        component="img"
        sx={{ 
          width: { xs: '100%', sm: 180 },
          height: { xs: 250, sm: '100%' },
          objectFit: 'cover'
        }}
        image={item.posterPath ? `https://image.tmdb.org/t/p/w500${item.posterPath}` : '/placeholder.png'}
        alt={item.title}
      />
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        flex: 1,
        minHeight: 0,
        width: '100%',
        overflow: 'hidden'
      }}>
        <CardContent sx={{ 
          flex: 1, 
          display: 'flex', 
          flexDirection: 'column',
          height: '100%',
          p: { xs: 1.5, sm: 2 },
          '&:last-child': { pb: { xs: 1.5, sm: 2 } }
        }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1.5, gap: 1 }}>
            <Typography 
              variant="h6" 
              sx={{ 
                fontSize: { xs: '1rem', sm: '1.1rem' },
                lineHeight: 1.2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical'
              }}
            >
              {item.title}
            </Typography>
            <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center', flexShrink: 0 }}>
              <Chip 
                label={type === 'movie' ? 'Movie' : 'TV Show'} 
                color="primary" 
                size="small"
                sx={{ 
                  height: '24px',
                  '& .MuiChip-label': {
                    px: 1,
                    fontSize: '0.75rem'
                  }
                }}
              />
              <IconButton 
                size="small" 
                onClick={onDelete}
                color="error"
                sx={{ p: 0.5 }}
              >
                <DeleteIcon sx={{ fontSize: '1.2rem' }} />
              </IconButton>
            </Box>
          </Box>
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              mb: 1.5,
              display: '-webkit-box',
              WebkitLineClamp: { xs: 3, sm: 4 },
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              lineHeight: 1.3,
              flex: 1,
              fontSize: { xs: '0.8rem', sm: '0.85rem' }
            }}
          >
            {item.overview}
          </Typography>
          <Box sx={{ mt: 'auto' }}>
            {item.rating && (
              <Rating value={item.rating} readOnly size="small" sx={{ mb: 1 }} />
            )}
            <Box sx={{ 
              display: 'flex', 
              gap: 1,
              flexDirection: { xs: 'column', sm: 'row' },
              width: '100%'
            }}>
              <Button
                size="small"
                variant="outlined"
                onClick={onMarkFinished}
                startIcon={<CheckIcon sx={{ fontSize: '1rem' }} />}
                fullWidth
                sx={{
                  textTransform: 'none',
                  minHeight: { xs: '28px', sm: '32px' },
                  fontSize: { xs: '0.7rem', sm: '0.75rem' },
                  py: 0.25,
                  px: 0.75,
                  whiteSpace: 'nowrap',
                  flex: { sm: '0 0 45%' }
                }}
              >
                Finished
              </Button>
              <Button
                size="small"
                variant="outlined"
                onClick={onMoveBack}
                startIcon={<ArrowBackIcon sx={{ fontSize: '1rem' }} />}
                fullWidth
                sx={{
                  textTransform: 'none',
                  minHeight: { xs: '28px', sm: '32px' },
                  fontSize: { xs: '0.7rem', sm: '0.75rem' },
                  py: 0.25,
                  px: 0.75,
                  whiteSpace: 'nowrap',
                  flex: { sm: '0 0 45%' }
                }}
              >
                Watchlist
              </Button>
            </Box>
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

const EmptyCard = ({ type }: { type: 'movie' | 'tv' }) => (
  <Card sx={{ 
    display: 'flex', 
    mb: 2,
    animation: `${glowAnimation} 2s infinite ease-in-out`,
    bgcolor: 'background.paper',
    borderRadius: 2,
    height: { xs: 'auto', sm: '300px' },
    maxWidth: { sm: '800px' },
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }}>
    <Box sx={{ 
      textAlign: 'center', 
      p: 3,
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Typography 
        variant="h6" 
        gutterBottom 
        color="primary" 
        sx={{ 
          fontSize: { xs: '1rem', sm: '1.25rem' },
          mb: 2
        }}
      >
        No {type === 'movie' ? 'Movie' : 'TV Show'} Playing
      </Typography>
      <Typography 
        variant="body2" 
        color="text.secondary"
        sx={{
          fontSize: { xs: '0.8rem', sm: '0.9rem' }
        }}
      >
        Add something from your watchlist to start watching!
      </Typography>
    </Box>
  </Card>
);

const convertToSerializedItem = (item: MediaItem | null, watchlistId: string): SerializedWatchlistItem | null => {
  if (!item) return null;
  return {
    ...item,
    watchlistId,
    addedAt: Date.now(), // Current timestamp since this is the currently playing item
    addedBy: '', // We don't track who added currently playing items
  };
};

export const NowPlaying = () => {
  const dispatch = useDispatch();
  const activeWatchlist = useSelector((state: RootState) => state.watchlist.activeWatchlist);
  const currentUser = useSelector((state: RootState) => state.auth.user);

  const handleMarkFinished = async (type: 'movie' | 'tv') => {
    if (!activeWatchlist?.id) return;
    try {
      await markAsFinished(activeWatchlist.id, type);
    } catch (error) {
      console.error('Error marking as finished:', error);
    }
  };

  const handleMoveBack = async (type: 'movie' | 'tv') => {
    if (!activeWatchlist?.id || !currentUser) return;
    try {
      const currentItem = type === 'movie' ? activeWatchlist.currentMovie : activeWatchlist.currentTvShow;
      if (!currentItem) return;

      // First add it back to the watchlist using the new function
      await moveItemBackToWatchlist(activeWatchlist.id, {
        ...currentItem,
        addedBy: currentUser.id, // Set the current user as the one who moved it back
        addedAt: Date.now(),
      });

      // Then clear it from currently watching
      await updateCurrentlyWatching(activeWatchlist.id, null, type);
    } catch (error) {
      console.error('Error moving back to watchlist:', error);
    }
  };

  const handleDelete = async (type: 'movie' | 'tv') => {
    if (!activeWatchlist?.id) return;
    try {
      await updateCurrentlyWatching(activeWatchlist.id, null, type);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };

  if (!activeWatchlist) return null;

  const currentMovie = activeWatchlist.currentMovie && convertToSerializedItem(activeWatchlist.currentMovie, activeWatchlist.id);
  const currentTvShow = activeWatchlist.currentTvShow && convertToSerializedItem(activeWatchlist.currentTvShow, activeWatchlist.id);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography 
        variant="h4" 
        align="center"
        sx={{ 
          mb: { xs: 2, sm: 4 },
          fontWeight: 'bold',
          color: '#ff0000',
          animation: `${marqueeGlow} 2s infinite ease-in-out`,
          textTransform: 'uppercase',
          letterSpacing: '0.1em',
          fontFamily: '"Roboto Condensed", sans-serif',
          borderBottom: '2px solid #ff0000',
          paddingBottom: '8px',
          width: 'fit-content',
          margin: '0 auto',
          fontSize: { xs: '1.5rem', sm: '2.125rem' }
        }}
      >
        Now Playing
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        gap: { xs: 2, sm: 4 }, 
        justifyContent: 'center', 
        width: '100%',
        flexDirection: { xs: 'column', sm: 'row' },
        px: { xs: 1, sm: 0 }
      }}>
        {/* Movies Section */}
        <Box sx={{ 
          flex: 1, 
          maxWidth: { xs: '100%', sm: '800px' },
          minWidth: { sm: '500px' },
          width: '100%'
        }}>
          <Typography 
            variant="h6" 
            gutterBottom 
            align="center"
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
          >
            Movie
          </Typography>
          {currentMovie ? (
            <MediaCard
              item={currentMovie}
              onMarkFinished={() => handleMarkFinished('movie')}
              onMoveBack={() => handleMoveBack('movie')}
              onDelete={() => handleDelete('movie')}
              type="movie"
            />
          ) : (
            <EmptyCard type="movie" />
          )}
        </Box>

        {/* TV Shows Section */}
        <Box sx={{ 
          flex: 1, 
          maxWidth: { xs: '100%', sm: '800px' },
          minWidth: { sm: '500px' },
          width: '100%'
        }}>
          <Typography 
            variant="h6" 
            gutterBottom 
            align="center"
            sx={{ fontSize: { xs: '1rem', sm: '1.25rem' } }}
          >
            TV Show
          </Typography>
          {currentTvShow ? (
            <MediaCard
              item={currentTvShow}
              onMarkFinished={() => handleMarkFinished('tv')}
              onMoveBack={() => handleMoveBack('tv')}
              onDelete={() => handleDelete('tv')}
              type="tv"
            />
          ) : (
            <EmptyCard type="tv" />
          )}
        </Box>
      </Box>
    </Box>
  );
}; 